// About.tsx
import React from 'react';

function About() {
    return (
        <div>
            <h1>About Us</h1>
            <div className="bio">
                <div className="portrait">
                    <img src="portrait.png" alt="Portrait" className="portrait" />
                </div>
                <div className="info">
                    <h2>Gerald Noonan</h2>
                    <p>Owner/Operator</p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                        malesuada mauris in pulvinar tincidunt. Nullam ac odio velit.
                        Curabitur id leo in libero consectetur congue. Nulla facilisi.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;