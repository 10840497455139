// Contact.tsx
import React from 'react';

function Contact() {
    return (
        <div>
            <h1>Contact Us</h1>
            
        </div>    );
}

export default Contact;
