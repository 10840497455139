// Header.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo.svg';

function Header() {
    return (
        <header>
            <div className="header">
                <Link to="/">
                    <img src={logo} className="App-logo" alt="Logo" />
                </Link>
                <nav>
                    <ul>
                        {/*<li> <img src={logo} className="App-logo" alt="logo" /></li>*/}
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/about">About</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default Header;