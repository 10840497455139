// Home.tsx
import React from 'react';

function Home() {
    return (
        <div className="Body">
            <h1>Welcome to Our Website!</h1>
            <p>This is the homepage of our website.</p>
        </div>
    );
}

export default Home;